import React from 'react'

import { locationData } from '../../assets/locationData.js'

class LocationPage extends React.Component
{
	render()
	{
		const location = locationData.getLocationData(this.props.id);

		const picture = (location.picture ?
			<img className="location" src={location.picture} alt={location.name} />
			: null);

		const phonehref = "tel://" + location.phone.replace(/[\D]+/g, '');

		const address = (location.address ?
			<p>
				{location.address.street}<br />
				{location.address.street2 ? <React.Fragment>{location.address.street2}<br /></React.Fragment> : null}
				{location.address.city}, {location.address.state} {location.address.zip}<br />
				<span class="desktop-only">{location.phone}</span>
				<a class="mobile-only" href={phonehref}>{location.phone}</a>
			</p>
			: null);

		const hours = (location.hours ?
			<table>
				<tbody>
					{location.hours.map((date) =>
					{
						return <tr key={date.day}><th>{date.day}</th><td>{date.hours}</td></tr>;
					})}
				</tbody>
			</table>
			: null);

		const mapEmbedURL = (location.mapEmbedURL ? 
			<iframe src={location.mapEmbedURL} title={location.name + " Map"} frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false"></iframe> 
			: null);

		const byline = (location.byline ? <h4>{location.byline}</h4> : null);

		return (
			<div id="visitpage" className="wrapper style1">
				<div className="container">
					<header className="major">
						<h2>Hours &amp; Location</h2>
					</header>
					<div className="alt">
						<div key={location.id} className="row">
							<div className="col-4 col-12-medium">
								{picture}
							</div>
							<div className="col-4 col-12-medium">
								{/* <h3>{location.name}</h3> We don't need this on LocationPage's because we are already outputting the location title */}
								{address}
								{mapEmbedURL}
							</div>
							<div className="col-4 col-12-medium">
								{byline}
								{hours}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default LocationPage