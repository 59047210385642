import React from 'react'
import Layout from '../components/layout'
import EllisHelmet from '../components/EllisHelmet'
import LocationMenu from '../components/location-specific/LocationMenu'
import LocationPage from '../components/location-specific/LocationPage'

class LocationDowntown extends React.Component
{
	render()
	{
		const location = "downtown";
		const currentPage = "location";
		const title = null;

		return (
			<Layout className="menuPage" location={location} currentPage={currentPage}>
				<EllisHelmet page={currentPage} title={title} location={location} />
				<LocationMenu location={location} currentPage={currentPage} />
				<LocationPage id={location} />
			</Layout>
		)
	}
}

export default LocationDowntown